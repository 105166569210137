import React from "react";

function Icon() {
  return (
    <svg width="24" height="24" x="0" y="0" version="1.1" viewBox="0 0 24 24">
      <path
        fill="#54656f"
        d="M12.072 1.761a10.05 10.05 0 00-9.303 5.65.977.977 0 001.756.855 8.098 8.098 0 017.496-4.553.977.977 0 10.051-1.952zM1.926 13.64a10.052 10.052 0 007.461 7.925.977.977 0 00.471-1.895 8.097 8.097 0 01-6.012-6.386.977.977 0 00-1.92.356zm13.729 7.454a10.053 10.053 0 006.201-8.946.976.976 0 10-1.951-.081v.014a8.097 8.097 0 01-4.997 7.209.977.977 0 00.727 1.813l.02-.009z"
      ></path>
      <path fill="#009588" d="M19 1.5a3 3 0 110 6 3 3 0 010-6z"></path>
    </svg>
  );
}

export default Icon;
