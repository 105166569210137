const Message = () => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.25 4.5C4.00736 4.5 3 5.50736 3 6.75V15.75C3 16.9926 4.00736 18 5.25 18H6V21C6 21.291 6.16835 21.5558 6.43188 21.6792C6.69542 21.8026 7.00658 21.7625 7.23014 21.5762L11.5215 18H18.75C19.9926 18 21 16.9926 21 15.75V6.75C21 5.50736 19.9926 4.5 18.75 4.5H5.25ZM7.5 12.75C7.5 12.3358 7.83579 12 8.25 12L14.25 12C14.6642 12 15 12.3358 15 12.75C15 13.1642 14.6642 13.5 14.25 13.5L8.25 13.5C7.83579 13.5 7.5 13.1642 7.5 12.75ZM8.25 9C7.83579 9 7.5 9.33579 7.5 9.75C7.5 10.1642 7.83579 10.5 8.25 10.5H11.25C11.6642 10.5 12 10.1642 12 9.75C12 9.33579 11.6642 9 11.25 9L8.25 9Z"
      fill="#9E9EA8"
    />
  </svg>
);
export default Message;
