import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1300"
      height="1300"
      fill="none"
      viewBox="0 0 1300 1300"
    >
      <path
        fill="#F4DA92"
        d="M650 1300C290.71 1300 0 1008.86 0 650 0 290.71 291.141 0 650 0c359.29 0 650 291.141 650 650 .43 359.29-290.71 650-650 650z"
      ></path>
      <mask
        id="mask0_32_1442"
        style={{ maskType: "alpha" }}
        width="1301"
        height="1300"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#F7C0B5"
          d="M650 1300C290.71 1300 0 1008.86 0 650 0 290.71 291.141 0 650 0c359.29 0 650 291.141 650 650 .43 359.29-290.71 650-650 650z"
        ></path>
      </mask>
      <g mask="url(#mask0_32_1442)">
        <path
          fill="#FEE4BF"
          d="M1015.73 1197.33c-3.03 2.17-6.5 4.33-9.53 6.5-3.47 2.17-6.502 4.33-9.969 6.07-3.033 1.73-5.633 3.46-8.667 5.2-.433.43-.866.43-1.299.86-9.967 6.07-20.367 11.7-30.767 16.9a96.314 96.314 0 01-9.533 4.77s-.434.43-.867.43c-3.467 1.74-6.933 3.47-10.4 5.2-6.933 3.47-14.3 6.5-21.233 9.54-3.467 1.73-7.367 3.03-10.834 4.33-3.466 1.3-7.366 3.03-10.833 4.33-3.467 1.3-7.367 3.04-10.833 4.34h-.434c-3.467 1.3-7.366 2.6-10.833 3.9-7.8 2.6-16.033 5.2-23.834 7.8-2.599.86-5.633 1.73-8.233 2.6a261.519 261.519 0 01-24.7 6.5c-3.033.86-6.067 1.3-9.533 2.16-7.8 1.74-16.034 3.47-23.834 5.2-1.733.44-3.033.44-4.766.87-2.6.43-5.633.87-8.234 1.3-3.899.43-7.366 1.3-11.266 1.73-1.733.44-3.467.44-5.2.87-2.167.43-4.767.87-7.367.87-6.933.86-14.3 1.73-21.233 2.6-2.167.43-4.767.43-6.933.86h-.434c-3.033.44-6.067.44-9.1.87-3.033.43-6.066.43-9.1.43-1.733 0-3.9.44-5.633.44-3.467 0-6.5.43-9.533.43-4.334 0-8.234.43-12.567.43h-21.667c-182.433-2.6-347.1-80.16-464.1-203.23 0 0 0-.43-.433-.43 13.433-26.44 63.267-74.1 115.267-113.536 65.866-49.833 159.033-80.599 159.033-80.599s61.1-9.967 287.733 37.266c172.9 36.4 246.567 184.169 271.699 252.199z"
        ></path>
        <path
          fill="#FEE4BF"
          d="M792.131 1065.16c-9.1 44.64-68.033 71.07-167.267 31.2-121.766-49.4-168.566-188.495-168.566-188.495-6.5-99.667-21.667-185.034-21.667-185.034 140.834-12.133 307.233 152.534 307.233 152.534l19.067 81.466c1.733 6.933 5.2 13 10.4 17.334 9.1 15.599 26.867 62.395 20.8 90.995z"
        ></path>
        <path
          fill="#EFBDA5"
          d="M771.331 974.598c-82.767-4.333-143.867-16.467-209.3-133.9 55.467 48.967 120.9 67.6 189.8 76.267l9.1 39.866c1.733 7.367 5.2 13.434 10.4 17.767z"
        ></path>
        <path
          fill="#FEE4BF"
          d="M942.498 452.431c7.8 36.4 11.7 65.867-9.534 162.934-21.233 97.066 19.5 194.133-82.766 282.966-68.9 59.8-189.367 71.067-285.567 5.634-32.5-22.1-62.4-52.867-86.667-93.167-19.066-31.633-41.166-61.533-65.866-88.833-34.234-37.267-75.4-112.234-55.033-244.834 32.933-215.8 246.566-240.5 372.666-216.666 126.1 23.833 191.533 95.766 212.767 191.966z"
        ></path>
        <path
          fill="#FEE4BF"
          d="M527.798 621.865s-13.433-65-76.7-57.2c-54.6 6.933-73.667 137.8 50.266 174.2"
        ></path>
        <path
          stroke="#423232"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="13.479"
          d="M497.464 679.498s-15.6-9.967-13.866-27.3c2.6-20.367 0-32.933-9.533-36.833-23.834-9.967-28.6 17.766-27.734 21.233"
        ></path>
        <path
          fill="#EFBDA5"
          d="M828.098 721.531s16.466.867 32.066-17.766c8.667-10.4 16.034-23.4 11.267-27.3-4.767-4.334-83.2 7.8-88.4 18.2-5.2 10.4 25.133 26.866 45.067 26.866z"
        ></path>
        <path
          stroke="#423232"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="13.479"
          d="M841.098 575.931s.866 41.167 27.3 87.1c11.7 20.367-6.067 53.734-44.633 59.367"
        ></path>
        <path
          fill="#F2A5B2"
          d="M606.231 743.631c24.651 0 44.634-16.491 44.634-36.833 0-20.343-19.983-36.833-44.634-36.833-24.65 0-44.633 16.49-44.633 36.833 0 20.342 19.983 36.833 44.633 36.833zM926.031 670.831c-1.3 24.267-.866 48.1-2.6 71.934-19.066-4.334-32.933-18.634-32.933-35.534 0-18.2 15.166-32.933 35.533-36.4z"
          opacity="0.42"
        ></path>
        <path
          fill="#BEE3EB"
          d="M1158.93 1465.49H63.462s6.5-201.07 72.367-344.5c49.833-108.34 167.266-168.569 254.8-199.769 24.7-8.667 47.233-15.167 64.566-19.934 0 2.167.434 3.9.434 6.067 0 0 46.8 139.096 168.566 188.496 99.234 40.3 158.167 13.44 167.267-31.2 5.633-28.6-12.133-75.396-21.233-90.563-5.2-4.333-8.667-10.833-10.4-17.333l-3.467-14.3c8.233-1.733 16.033-3.9 23.833-6.5 2.6-.867 4.767-1.733 7.367-2.6 55.9 14.733 139.966 49.833 239.198 133.036 112.24 93.6 132.17 399.1 132.17 399.1z"
        ></path>
        <path
          fill="#fff"
          d="M586.495 1140.92c-149.5-67.17-207.567-187.199-196.3-219.699 0 0 0-.434.433-.867 7.8-16.033 20.367-29.033 35.967-38.133 8.666-5.2 18.2-9.967 26-12.567.433 6.066 2.166 25.133 2.6 31.633 0 2.167.433 3.9.433 6.067 0 0 27.733 103.566 149.5 152.966 99.233 40.3 160.767 13.87 167.267-31.2 7.366-49.833-20.8-85.8-20.8-85.8 8.233-1.733 21.233-4.766 29.033-7.366 19.067 22.967 38.567 45.933 45.933 106.596 10.4 87.1-85.366 168.14-240.066 98.37z"
        ></path>
        <path
          stroke="#544D4D"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="13.479"
          d="M277.962 1465.49l-19.5-160.77M1034.56 1250.12s-13 31.63-9.1 63.27c3.9 31.63 15.17 78-.86 152.1"
        ></path>
        <path
          fill="#423232"
          d="M683.943 619.439c-10.4-.433-18.2-9.1-17.333-19.5l.433-12.133c.433-10.4 9.1-18.2 19.5-17.334 10.4.434 18.2 9.1 17.333 19.5l-.433 12.134c-.433 9.966-9.1 17.766-19.5 17.333zM866.376 625.939c-9.533-.434-16.9-8.667-16.467-18.2l.434-11.267c.433-9.533 8.666-16.9 18.2-16.467 9.533.434 16.9 8.667 16.466 18.2l-.433 11.267c-.433 9.533-8.667 16.9-18.2 16.467z"
        ></path>
        <path
          stroke="#423232"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="13.479"
          d="M725.977 594.305s-11.267-16.033-30.767-19.066c-16.033-2.6-33.8 5.2-36.4 14.3M853.81 598.206s0-13.867 19.933-13.867c16.467 0 23.4 15.167 23.4 15.167"
        ></path>
        <path
          fill="#423232"
          d="M743.743 524.539c9.1 3.033 18.2-5.2 16.466-15.167-.866-5.2-3.033-10.4-7.366-14.3-13-11.7-66.734-22.1-99.234-6.933-28.6 13.866 48.534 22.533 90.134 36.4zM873.308 531.472c-7.366 2.6-15.167-5.2-13.867-14.3.434-4.766 2.167-9.533 5.634-13 10.4-10.4 45.933-18.633 68.467-2.6 19.5 13.434-26.434 17.334-60.234 29.9z"
        ></path>
        <path
          stroke="#423232"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="13.479"
          d="M816.925 774.866c-11.232 4.942-16.623-4.942-34.145 3.594-12.581 5.841-19.32 7.638-34.596-1.348-9.884-6.29-22.015 2.696-33.696-14.377"
        ></path>
        <path
          fill="#423232"
          d="M766.187 939.663c31.633-8.233 59.8-22.533 82.767-42.466 102.266-88.4 61.533-185.901 82.766-282.967 6.934-31.2 10.834-55.467 13.434-74.967-3.467-.433-7.367-.867-11.267-1.733-15.6-2.167-26-16.467-24.267-32.067.867-6.5 1.3-13 1.734-19.933-1.734-.434-1.3-.433-3.034-.433-2.6 8.233-5.633 16.899-7.8 25.133-3.9 13-16.466 21.233-29.466 20.367h-.434c-15.167-1.3-26.433-13.867-26.433-29.034.433-10.833.433-21.667.867-32.5-1.734-.433-.867 0-2.6-.433-2.167 11.266-4.334 22.533-6.5 34.233-2.601 14.3-15.167 24.267-29.467 23.4-37.267-1.733-74.1-2.166-107.467-2.166-14.733 0-26.866-10.834-28.166-25.567-2.167-19.067-5.2-38.134-10.4-57.634-5.634 17.334-5.634 35.534-4.767 54.167.867 16.033-11.7 29.467-27.733 29.9-38.567.867-64.567 2.167-68.9 2.6 1.3 59.367-12.134 125.233-35.967 80.6-1.733-3.033-3.033-6.067-4.767-9.967-9.966-17.766-29.9-39-67.6-34.233-52.866 6.933-72.366 129.567 38.567 170.3.433 9.1 2.6 18.2 6.5 27.733 16.9 41.167 24.267 75.4 24.267 103.567 0 163.367-267.367 95.333-342.767-52.867-29.033-57.633-29.467-126.966 25.567-199.766 134.766-165.1 94.466-102.267 124.366-200.2 48.967-160.334 160.767-211.467 308.967-210.167 0 0 246.567 0 312 59.8 65.433 59.8 42.9 246.567 37.266 263.467-1.733 5.2-6.066 8.233-10.833 10.4-2.6 85.366 6.067 115.266 36.4 184.166 66.3 151.667-58.933 295.964-244.833 219.267z"
        ></path>
        <path
          fill="#FEE4BF"
          d="M523.087 608.596c-1.734-3.034-3.034-6.067-4.767-9.967 1.733 3.467 3.467 6.933 4.767 9.967z"
        ></path>
        <path
          fill="#B7AEA9"
          d="M865.083 677.253s5.2 29.467 39.433 44.2c8.667 3.467 17.767-2.167 17.767-11.7-.433 0-55.033-29.033-57.2-32.5zM782.75 606.187s27.733-27.734 41.6-30.767l-36.834 8.666-4.766 22.101zM597.717 677.254s16.033 36.399 64.133 45.066c48.1 9.1 91.867-25.566 112.233-83.633 0 0-16.466 53.733-82.333 63.7-65.433 10.833-94.033-25.133-94.033-25.133z"
          opacity="0.6"
        ></path>
        <path
          fill="#DBC3ED"
          d="M675.283 482.687c-61.1 0-110.933 51.133-111.367 113.533 0 29.467 10.834 56.334 28.6 75.834l157.3-159.034c-19.5-19.066-45.5-30.333-74.533-30.333z"
          opacity="0.29"
        ></path>
        <path
          fill="#C4ADD8"
          d="M749.817 513.02l-157.3 159.034c19.933 22.1 48.1 35.533 79.3 35.533 60.233 0 109.633-49.833 111.366-111.8.867-32.5-12.133-61.967-33.366-82.767z"
          opacity="0.23"
        ></path>
        <path
          fill="#DBC3ED"
          d="M928.783 482.687c-46.367 0-85.367 51.133-87.1 113.533-.867 25.134 4.767 48.1 14.3 66.734L993.35 526.887c-14.3-26.867-37.7-44.2-64.567-44.2z"
          opacity="0.29"
        ></path>
        <path
          fill="#C4ADD8"
          d="M993.349 526.453L855.983 662.52c14.3 27.3 37.7 45.066 65 45.066 45.5 0 84.497-49.833 87.097-111.8.87-25.566-4.76-49.833-14.731-69.333z"
          opacity="0.23"
        ></path>
        <path
          stroke="#4EC2DD"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="13.479"
          d="M783.183 596.22c-1.3 61.967-51.134 111.8-111.367 111.8-60.233 0-108.333-49.833-108.333-111.8.433-62.4 50.266-113.533 111.366-113.533S784.916 533.82 783.183 596.22zM1008.08 596.22c-2.6 61.967-41.597 111.8-87.097 111.8-45.5 0-81.033-49.833-79.3-111.8 1.733-62.4 40.733-113.533 87.1-113.533s81.897 51.133 79.297 113.533zM564.783 584.086H503.25M783.616 584.086s51.567-19.933 58.067 0"
        ></path>
      </g>
    </svg>
  );
}

export default Icon;
