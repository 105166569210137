import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1300"
      height="1300"
      fill="none"
      viewBox="0 0 1300 1300"
    >
      <path
        fill="#B5E2DC"
        d="M650 1300C290.71 1300 0 1008.86 0 650 0 290.71 291.141 0 650 0c359.29 0 650 291.141 650 650 .43 359.29-290.71 650-650 650z"
      ></path>
      <mask
        id="mask0_32_1452"
        style={{ maskType: "alpha" }}
        width="1301"
        height="1300"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#F7C0B5"
          d="M650 1300C290.71 1300 0 1008.86 0 650 0 290.71 291.141 0 650 0c359.29 0 650 291.141 650 650 .43 359.29-290.71 650-650 650z"
        ></path>
      </mask>
      <g mask="url(#mask0_32_1452)">
        <path
          fill="#AD827B"
          d="M1014.87 1197.33c-3.04 2.17-6.5 4.33-9.54 6.5-3.46 2.17-6.497 4.33-9.964 6.07-3.033 1.73-5.633 3.46-8.667 5.2-.433.43-.866.43-1.3.86-9.966 6.07-20.366 11.7-30.766 16.9a96.329 96.329 0 01-9.534 4.77s-.433.43-.866.43c-3.467 1.74-6.934 3.47-10.4 5.2-6.934 3.47-14.3 6.5-21.234 9.54-3.466 1.73-7.366 3.03-10.833 4.33-3.467 1.3-7.367 3.03-10.833 4.33-3.467 1.3-7.367 3.04-10.834 4.34h-.433c-3.467 1.3-7.367 2.6-10.833 3.9-7.8 2.6-16.034 5.2-23.834 7.8-2.6.86-5.633 1.73-8.233 2.6a261.599 261.599 0 01-24.7 6.5c-3.033.86-6.067 1.3-9.533 2.16-7.8 1.74-16.034 3.47-23.834 5.2-1.733.44-3.033.44-4.766.87-2.6.43-5.634.87-8.234 1.3-3.9.43-7.366 1.3-11.266 1.73-1.734.44-3.467.44-5.2.87-2.167.43-4.767.87-7.367.87-6.933.86-14.3 1.73-21.233 2.6-2.167.43-4.767.43-6.934.86h-.433c-3.033.44-6.067.44-9.1.87-3.033.43-6.067.43-9.1.43-1.733 0-3.9.44-5.633.44-3.467 0-6.5.43-9.534.43-4.333 0-8.233.43-12.566.43h-21.667c-182.433-2.6-347.1-80.16-464.1-203.23 0 0 0-.43-.433-.43 13.433-26.44 63.266-74.1 115.266-113.536 65.867-49.833 159.034-80.599 159.034-80.599s61.1-9.967 287.733 37.266c172.9 36.4 246.567 184.169 271.704 252.199z"
        ></path>
        <path
          fill="#AD827B"
          d="M791.266 1065.16c-9.1 44.64-68.034 71.07-167.267 31.2-121.766-49.4-168.566-188.495-168.566-188.495-6.5-99.667-21.667-185.034-21.667-185.034 140.833-12.133 307.233 152.534 307.233 152.534l19.067 81.466c1.733 6.933 5.2 13 10.4 17.334 9.1 15.599 26.866 62.395 20.8 90.995z"
        ></path>
        <path
          fill="#8E5650"
          d="M770.466 974.598c-82.767-4.333-143.867-16.467-209.3-133.9 55.467 48.967 120.9 67.6 189.8 76.267l9.1 39.866c1.733 7.367 5.2 13.434 10.4 17.767z"
        ></path>
        <path
          fill="#AD827B"
          d="M941.632 452.431c7.8 36.4 11.7 65.867-9.533 162.934-21.233 97.066 19.5 194.133-82.767 282.966-68.9 59.8-189.366 71.067-285.566 5.634-32.5-22.1-62.4-52.867-86.667-93.167-19.067-31.633-41.167-61.533-65.867-88.833-34.233-37.267-75.4-112.234-55.033-244.834 32.933-215.8 246.567-240.5 372.667-216.666 126.1 23.833 191.533 95.766 212.766 191.966z"
        ></path>
        <path
          fill="#AD827B"
          d="M526.933 621.865s-13.433-65-76.7-57.2c-54.6 6.933-73.667 137.8 50.267 174.2"
        ></path>
        <path
          stroke="#423232"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="13.479"
          d="M496.6 679.498s-15.6-9.967-13.867-27.3c2.6-20.367 0-32.933-9.533-36.833-23.834-9.967-28.6 17.766-27.734 21.233"
        ></path>
        <path
          fill="#8E605C"
          d="M827.233 721.531s16.467.867 32.067-17.766c8.667-10.4 16.033-23.4 11.267-27.3-4.767-4.334-83.2 7.8-88.4 18.2-5.2 10.4 25.133 26.866 45.066 26.866z"
        ></path>
        <path
          stroke="#423232"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="13.479"
          d="M840.233 575.931s.866 41.167 27.3 87.1c11.7 20.367-6.067 53.734-44.634 59.367"
        ></path>
        <path
          fill="#544D4D"
          d="M496.385 947.754s-8.667-53.733-23.4-50.7c-68.467 15.6-268.233 73.667-338 224.466-65.867 143.43-71.933 344.5-71.933 344.5H1158.95s-19.93-305.5-131.73-399.1c-66.302-55.9-126.102-90.132-175.068-110.932-65-27.734-130.434 30.333-149.067 29.033-33.367-1.733-206.7-37.267-206.7-37.267z"
        ></path>
        <path
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="13.479"
          d="M277.551 1466.02l-19.5-160.77M1034.15 1250.65s-13 31.64-9.1 63.27c3.9 31.63 15.17 78-.87 152.1"
        ></path>
        <path
          fill="#fff"
          d="M446.552 853.287s-31.2 11.267-36.833 17.334c-5.634 6.066-8.234 9.966-8.234 9.966s-15.6 17.334-24.266 59.367c-3.9 17.767 5.2 46.8-1.734 69.766-19.933 63.7 74.534 161.63 230.534 219.27 118.3 43.76 248.733 3.9 266.933-78.44 17.333-79.3 6.933-115.26-1.3-140.83-16.467-49.399-21.667-63.266-43.767-82.333-13.866-12.133-23.4 13-75.4 27.3-16.033 4.334-35.533 7.8-60.666 8.667-149.5 5.633-212.767-79.3-235.3-103.567-5.634-4.766-8.234-7.366-9.967-6.5z"
        ></path>
        <path
          stroke="#CCC"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="13.479"
          d="M751.185 954.254c-16.034 4.333-35.534 7.8-60.667 8.667-149.5 5.633-212.767-79.3-235.3-103.567"
        ></path>
        <path
          fill="#fff"
          d="M830.485 911.788c39.434 32.933 107.9 119.162 91.434 312.002-8.234 95.33 38.133 134.76 27.733 149.93-1.733 2.6-3.9 3.9-5.634 4.33-7.366 1.74-14.299 5.2-17.766 12.14-1.3 3.03-3.467 5.2-6.5 6.93-2.6 1.73-5.634 1.73-8.234 1.3-9.1-2.17-19.066 3.03-24.699 10.83-2.167 3.04-4.767 5.2-8.667 6.07-2.6.87-5.2.43-6.934 0-7.366-2.17-15.6 0-22.1 5.2-1.733 1.3-3.466 2.17-5.633 2.17-3.9 0-6.933-1.74-9.533-4.34-6.067-5.63-14.734-7.8-23.4-4.76-.867.43-1.3.43-2.167.43-6.067.87-10.833-3.9-14.3-12.13-3.466-7.8-11.7-11.27-20.367-10.4-1.733 0-3.466 0-5.633-.44-16.9-3.9-38.567-27.3-39-55.9-.433-28.16 6.067-77.13 16.9-100.1 33.367-70.63 45.5-121.33 47.233-159.46 3.467-69.77-40.733-132.169-36.4-133.469 44.2-9.1 73.667-30.333 73.667-30.333z"
        ></path>
        <path
          stroke="#CCC"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="13.479"
          d="M745.552 1235.05c33.366-70.63 45.499-121.33 47.233-159.46 2.6-53.74-23.4-103.569-32.933-123.936"
        ></path>
        <path
          stroke="#CCC"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="13.479"
          d="M728.652 1335.15c-.434-28.16 6.066-77.13 16.9-100.1 33.366-70.63 45.5-121.33 47.233-159.46M767.219 1183.92s-131.734 13-218.4-52.43M802.753 955.554c12.133 17.767 26 43.767 29.466 75.836M857.786 1415.32s-22.1-46.8 0-148.63c23.4-108.34 12.567-161.64 12.567-161.64M376.352 961.621s29.033 59.799 64.133 87.529M789.319 1105.05s-82.333 5.64-140.4-16.9"
        ></path>
        <path
          fill="#423232"
          d="M743.043 526.549c9.533 1.3 16.9-8.667 13.433-18.2-1.733-5.2-4.766-9.967-9.533-13-14.734-9.1-69.767-9.967-98.8 10.833-25.567 19.067 51.566 14.3 94.9 20.367zM863.074 531.315c-7.8 0-12.566-9.966-8.666-18.2 2.166-4.333 5.2-8.233 9.533-10.4 13.433-6.5 49.4-2.6 65.433 19.5 14.3 19.5-30.333 8.667-66.3 9.1z"
        ></path>
        <path
          stroke="#423232"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="13.479"
          d="M727.876 581.148c-33.8 14.301-71.5-3.466-71.5-3.466M899.042 589.816s-23.4 14.3-52.866-2.6"
        ></path>
        <path
          fill="#423232"
          d="M804.158 772.845c.449 7.188-4.943 19.768-14.827 28.754-50.769-31.001-73.683-14.826-73.683-14.826-17.971-27.407-13.928-64.698 29.653-36.393 30.551 19.32 48.972 22.465 58.857 22.465z"
        ></path>
        <path
          fill="#FE9DAB"
          d="M789.331 801.599c-10.782 9.435-26.957 15.276-48.972 6.29-10.333-4.043-18.421-12.131-24.711-21.116 0 0 22.914-16.175 73.683 14.826z"
        ></path>
        <path
          stroke="#423232"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="13.479"
          d="M812.694 771.048s-18.87 10.333-67.393-20.667c-57.958-37.291-46.276 40.885-5.391 57.508 42.682 17.522 64.697-20.667 64.248-35.044M715.648 786.773s22.914-16.175 73.683 14.826"
        ></path>
        <path
          fill="#423232"
          d="M1007.78 624.095c-20.362-18.633-25.996-45.933-27.729-64.133 13 8.667 29.899-17.767 29.029-48.967-1.3-46.366-13.429-80.6-27.729-104.433 9.967-12.567 17.334-27.3 21.229-43.767 17.77-71.933-40.729-126.966-126.095-147.766-52.434-13-148.634-6.5-261.734-8.234-19.5-.433-39.866.433-59.8 2.6-24.266 2.6-48.1 7.8-70.2 16.9-35.966 13.434-68.9 34.667-94.033 65.433-44.2 54.6-56.767 117-58.933 165.534-2.167 57.633-16.034 114.4-43.334 165.533-29.466 55.034-47.666 113.533-18.633 163.8.867 1.3 1.733 2.6 2.6 4.333-13.867 68.467 63.267 62.401 78.433-6.933-3.033 13-5.2 24.267-6.5 32.067-10.4 68.033 39 76.7 63.7 29.467 2.6-4.767 9.1-24.267 16.034-48.967 0 4.333.433 8.667.866 12.567.867 94.9 68.467 103.133 64.567 39-1.733-28.6 3.033-68.467 10.4-111.8-123.067-36.834-104-166.834-48.967-173.767 63.267-8.234 76.7 57.2 76.7 57.2 4.767 20.366 11.7 44.633 22.1 56.333 18.634 20.367 50.7 6.067 29.9-58.066-4.333-13.434-8.233-28.6-10.833-44.634 16.467 4.333 30.767-4.767 23.833-22.966-6.5-16.467-9.966-31.634-10.833-45.934-1.3-22.1 4.333-42.033 13.867-58.5 9.966 39.434 51.566 27.3 54.166-1.3.867-9.533 3.9-20.8 8.234-33.366 10.4 13.433 24.7 25.999 42.466 35.099 32.934 16.467 56.334 8.667 61.534-8.233 21.233 7.367 40.3 13 55.033 16.467 44.2 10.833 87.967 5.2 122.2-13 1.733 3.9 2.6 7.367 3.033 8.667l1.3 6.5c3.9 18.633 6.067 36.833 4.334 64.133 0 1.3-.001 2.166-.434 3.467 0 .866 0 1.733-.433 2.6 0 2.166-.434 3.9-.434 6.066 0 1.3-.433 2.167-.433 3.467 0 1.733-.433 3.9-.866 6.067-.434 1.733-.434 3.466-.867 5.2 0 1.3-.433 3.033-.433 4.333-.434 2.6-.867 5.2-1.301 8.233 0 .433 0 1.3-.433 1.734-.433 3.466-1.3 6.933-1.733 10.833-2.167 12.133-4.767 25.133-7.8 40.3-18.2 84.066 9.967 169-49.4 247.867-2.6 23.4 17.767 52.866 42.9 22.1 19.5-23.834 10.4-103.567 9.1-119.6 1.733 16.9 28.6 70.633 53.733 62.833 13.869-4.333 19.929-11.7 17.769-37.267-2.6-35.966-30.769-71.5-34.669-123.933 26 22.1 62.829-16.467 35.529-41.167z"
        ></path>
        <path
          fill="#fff"
          d="M477.158 636.126s-34.666-5.2-32.066 43.333c1.3 24.7 1.3 38.134.866 45.067-.433 4.767 2.6 9.1 6.934 10.833 5.2 2.167 10.833 0 13.433-5.2 3.9-6.933 8.233-16.466 8.233-24.266 0-13 13.867-24.7 13.867-24.7s-12.567-10.834-11.267-45.067z"
        ></path>
        <path
          fill="#fff"
          d="M582.025 1471.59c-3.466-3.9-139.533-236.6-119.166-324.13 13.866-60.23-4.334-129.57-20.367-190.667-6.5-25.134-12.567-48.534-16.033-69.334-6.067-35.966-1.734-70.2 13-101.833 16.033-35.533 13.433-66.3 13.433-66.733l8.667-.867c0 1.3 2.6 33.8-14.3 71.067-13.867 29.9-17.767 62.833-12.134 97.066 3.467 20.367 9.534 43.767 16.034 68.9 16.466 61.971 34.666 132.601 20.366 195.001-19.066 83.2 116.567 315.9 117.434 316.77l-6.934 4.76z"
        ></path>
        <path
          fill="#E5E5E5"
          d="M446.392 704.592c.434 9.534 0 16.034 0 19.934-.433 4.766 2.6 9.1 6.934 10.833 5.2 2.167 10.833 0 13.433-5.2 3.9-6.933 8.233-16.467 8.233-24.267v-1.733c-9.1 3.467-19.5 6.067-28.6.433z"
        ></path>
        <path
          fill="#fff"
          d="M854.158 992.759c-3.033 19.931-3.466 51.571-4.333 88.401-1.3 71.07-43.333 347.97-63.7 391.73l-7.8-3.46c19.5-42.47 61.533-318.07 62.833-388.27.867-36.83 1.3-68.9 4.334-89.701 6.5-43.767 1.733-76.7-.867-90.567 1.3-1.3 2.6-2.166 3.9-3.466 1.3-.867 2.167-2.167 3.467-3.034 2.6 11.267 9.966 47.234 2.166 98.367z"
        ></path>
      </g>
    </svg>
  );
}

export default Icon;
