const SVGComponent = ( { size } )  => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 22 22"
    fill="#fff"
  >
    <path
      d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.08997 7.99959C8.32507 7.33126 8.78912 6.7677 9.39992 6.40873C10.0107 6.04975 10.7289 5.91853 11.4271 6.0383C12.1254 6.15808 12.7588 6.52112 13.215 7.06312C13.6713 7.60512 13.921 8.29112 13.92 8.99959C13.92 10.9996 10.92 11.9996 10.92 11.9996"
      stroke="#12A4C6"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11 16H11.01"
      stroke="#12A4C6"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SVGComponent;