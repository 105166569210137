const BackIcon = () => (
  <svg
    width={13}
    height={23}
    viewBox="0 0 13 23"
    fill="none"
  >
    <path
      d="M12.183 2.56066C12.7688 1.97487 12.7688 1.02513 12.183 0.43934C11.5972 -0.146447 10.6475 -0.146447 10.0617 0.43934L0.43934 10.0617C-0.146447 10.6475 -0.146447 11.5972 0.43934 12.183L10.0617 21.8054C10.6475 22.3912 11.5972 22.3912 12.183 21.8054C12.7688 21.2196 12.7688 20.2699 12.183 19.6841L3.62132 11.1224L12.183 2.56066Z"
      fill="#0584FE"
    />
  </svg>
);
export default BackIcon;