import React from "react";

function Icon() {
  return (
    <svg fill='#5f6368' viewBox="0 0 24 24">
      <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"></path>
    </svg>
  );
}

export default Icon;
