const Signal = () => (
  <svg
    width={15}
    height={15}
    viewBox="0 0 15 15"
    fill="none"
  >
    <path
      d="M14.2796 0.762939L0.112915 14.9296H14.2796V0.762939Z"
      fill="#5F6368"
    />
  </svg>
);
export default Signal;