const RecorderIcon = () => (
  <svg
    width={19}
    height={24}
    viewBox="0 0 19 24"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.99996 22H13C13.5522 22 14 22.4477 14 23C14 23.5128 13.6139 23.9355 13.1166 23.9933L13 24H4.99996C4.44767 24 3.99996 23.5523 3.99996 23C3.99996 22.4872 4.386 22.0645 4.88334 22.0067L4.99996 22H13H4.99996ZM17.1011 10.6035C17.6531 10.6197 18.0875 11.0804 18.0713 11.6324C18.0293 13.0647 17.5712 14.5824 16.7941 15.9284C14.3088 20.233 8.80451 21.7079 4.49988 19.2226C1.74634 17.6329 0.0719678 14.7458 0.000875111 11.6258C-0.0117064 11.0737 0.425693 10.6159 0.977835 10.6033C1.52998 10.5907 1.98777 11.0281 2.00036 11.5803C2.0557 14.0091 3.35737 16.2536 5.49988 17.4906C8.84792 19.4236 13.1291 18.2764 15.0621 14.9284C15.6789 13.86 16.0402 12.6628 16.0722 11.5737C16.0884 11.0217 16.549 10.5873 17.1011 10.6035ZM8.99996 0C11.7614 0 14 2.23858 14 5V11.5C14 14.2614 11.7614 16.5 8.99996 16.5C6.23853 16.5 3.99996 14.2614 3.99996 11.5V5C3.99996 2.23858 6.23853 0 8.99996 0Z"
      fill="#0584FE"
    />
  </svg>
);
export default RecorderIcon;