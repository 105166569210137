const Camera = () => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    fill="#9E9EA8"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.6939 8.96576C21.5918 7.62862 20.4694 6.60005 19.1429 6.49719L18.2245 6.39433C17.8163 6.39433 17.4082 6.18862 17.2041 5.77719L16.2857 4.33719C15.7755 3.51433 14.8571 3.00005 13.9388 3.00005H10.0612C9.04082 3.00005 8.22449 3.51433 7.71429 4.33719L6.79592 5.77719C6.59184 6.08576 6.18367 6.29147 5.77551 6.39433L4.85714 6.49719C3.53061 6.60005 2.5102 7.62862 2.30612 8.96576C2.20408 10.3029 2 12.1543 2 13.5943C2 15.0343 2.20408 16.8858 2.30612 18.2229C2.40816 19.56 3.53061 20.5886 4.85714 20.6915C6.89796 20.7943 9.7551 21 12 21C14.2449 21 17.102 20.7943 19.1429 20.6915C20.4694 20.5886 21.5918 19.56 21.6939 18.2229C21.7959 16.8858 22 15.0343 22 13.5943C22 12.1543 21.7959 10.2 21.6939 8.96576ZM12 16.9886C9.85714 16.9886 8.12245 15.24 8.12245 13.08C8.12245 10.92 9.85714 9.17147 12 9.17147C14.1429 9.17147 15.8776 10.92 15.8776 13.08C15.8776 15.24 14.1429 16.9886 12 16.9886Z"
      fill="#v"
    />
  </svg>
);
export default Camera;