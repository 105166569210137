import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill="none"
      viewBox="0 0 40 40"
    >
      <path
        fill="#fff"
        d="M31.844 13.333l1.316-2.916L36.078 9.1a.834.834 0 000-1.517l-2.916-1.316-1.317-2.934a.834.834 0 00-1.517 0L29.01 6.25l-2.933 1.317a.834.834 0 000 1.516l2.917 1.317 1.317 2.933c.3.65 1.233.65 1.533 0zm-13.267 2.5L15.927 10c-.583-1.3-2.45-1.3-3.033 0l-2.65 5.833-5.833 2.65c-1.3.6-1.3 2.45 0 3.034l5.833 2.65L12.894 30c.6 1.3 2.45 1.3 3.033 0l2.65-5.833 5.833-2.65c1.3-.6 1.3-2.45 0-3.034l-5.833-2.65zM30.31 26.667l-1.316 2.916-2.917 1.317a.834.834 0 000 1.517l2.917 1.316 1.317 2.934c.3.65 1.216.65 1.516 0l1.317-2.917 2.933-1.317a.834.834 0 000-1.516L33.161 29.6l-1.317-2.933c-.3-.65-1.234-.65-1.534 0z"
      ></path>
    </svg>
  );
}

export default Icon;
